<template>
  <div>
    <head>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <title>REFRACTION & OPTOMETRIST REPORT</title>

      <!-- Fonts -->
      <link
        href="https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap"
        rel="stylesheet"
      />
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css"
        integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
        crossorigin="anonymous"
      />
    </head>
    <body v-if="reportData">
      <div class="reportPage">
        <!-- <v-btn style="position:relative;top:80px;left:30px;" color="danger" hide-details small id="print" @click="print">{{
          $t("Print")
        }}</v-btn> -->

        <div class="reportDetails">
          <div name="page-header">
            <div class="header"></div>
            <div style="text-align: center">
              <div>
                <img
                  src="/assets/cropped-logo.png"
                  style="width: 120px; height: 80px"
                />
              </div>
              <h4
                class="mt-2"
                style="
                  line-height: 0.8rem;
                  font-family: HacenTunisia;
                  color: #1a5064;
                  font-weight: bold;
                "
              >
                الرؤية الجديدة للبصريات
              </h4>
              <h4
                style="
                  font-weight: bold;
                  color: #1a5064;
                  letter-spacing: -1.5px;
                  font-size: 22px;
                  font-style: italic;
                "
              >
                NEW VISION OPTICS
              </h4>

              <h5 class="mt-2 mb-1" style="font-weight: bold">
                REFRACTION & OPTOMETRIST REPORT
              </h5>
            </div>
          </div>

          <div name="page-content">
            <div class="container-fluid" style="padding-left: 30px">
              <div style="border: 1px solid #ccc"></div>
              <div class="row mt-0">
                <div class="col-md-9">
                  <p>
                    <span class="title-key">Name:</span>
                    <span class="value-key">{{ reportData.patient.name }}</span>
                  </p>
                </div>
                <div class="col-md-3">
                  <p>
                    <span class="title-key">Contact no:</span>
                    <span class="value-key">{{
                      reportData.patient.mobile
                    }}</span>
                  </p>
                </div>
              </div>

              <div class="row mt-0">
                <div class="col-md-3">
                  <p>
                    <span class="title-key">Gender:</span>
                    <span class="value-key">
                      <span
                        :class="
                          reportData.patient.gender == 0 ? 'active-gender' : ''
                        "
                        >M</span
                      >
                      /
                      <span
                        :class="
                          reportData.patient.gender !== 0 ? 'active-gender' : ''
                        "
                      >
                        F
                      </span>
                    </span>
                  </p>
                </div>

                <div class="col-md-3">
                  <p>
                    <span class="title-key">Date:</span>
                    <span class="value-key">{{ reportData.reg_date }}</span>
                  </p>
                </div>

                <div class="col-md-3">
                  <p>
                    <span class="title-key">Age:</span>
                    <span class="value-key">{{ reportData.patient.age }}</span>
                  </p>
                </div>

                <div class="col-md-3">
                  <p>
                    <span class="title-key">Birth date:</span>
                    <span class="value-key">{{
                      reportData.patient.birth_date
                    }}</span>
                  </p>
                </div>
              </div>

              <div class="row mt-0">
                <div class="col-md-12">
                  <p>
                    <span class="title-key2">CHIEF COMPLAINTS:</span>
                    <span
                      class="value-key"
                      v-html="reportData.chief_complaints"
                    ></span>
                  </p>
                </div>
              </div>

              <div class="row mt-0">
                <div class="col-md-12">
                  <p>
                    <span class="title-key2">PAST MEDICAL HISTORY:</span>
                    <span
                      class="value-key"
                      v-html="reportData.past_medical_history"
                    ></span>
                  </p>
                </div>
              </div>

              <div class="row mt-0">
                <div class="col-md-12">
                  <p>
                    <span class="title-key2">PAST OCULAR HISTORY:</span>
                    <span
                      class="value-key"
                      v-html="reportData.past_ocular_history"
                    ></span>
                  </p>
                </div>
              </div>

              <div class="row">
                <table id="table1" class="mt-0">
                  <tr>
                    <th>
                      <p class="title-key2">VISUAL ACUITY</p>
                    </th>
                    <th><p class="title-key">EYE</p></th>
                    <th><p class="title-key">UNAIDED</p></th>
                    <th><p class="title-key">AIDED (Glasses)</p></th>
                    <th><p class="title-key">AIDED (Contact lenses)</p></th>
                    <th><p class="title-key">PH</p></th>
                    <th></th>
                    <th><p class="title-key">UNAIDED</p></th>
                    <th><p class="title-key">AIDED</p></th>
                  </tr>
                  <tr>
                    <td rowspan="2">DISTANCE</td>
                    <td>RE</td>
                    <td>
                      {{ reportData.visual_acuities.distance_right_unaided }}
                    </td>
                    <td>
                      {{
                        reportData.visual_acuities.distance_right_aided_glasses
                      }}
                    </td>
                    <td>
                      {{
                        reportData.visual_acuities
                          .distance_right_aided_contact_lenses
                      }}
                    </td>
                    <td>{{ reportData.visual_acuities.distance_right_ph }}</td>
                    <td rowspan="2">Near</td>
                    <td>{{ reportData.visual_acuities.near_right_unaided }}</td>
                    <td>{{ reportData.visual_acuities.near_right_aided }}</td>
                  </tr>
                  <tr>
                    <td>LE</td>
                    <td>
                      {{ reportData.visual_acuities.distance_left_unaided }}
                    </td>
                    <td>
                      {{
                        reportData.visual_acuities.distance_left_aided_glasses
                      }}
                    </td>
                    <td>
                      {{
                        reportData.visual_acuities
                          .distance_left_aided_contact_lenses
                      }}
                    </td>
                    <td>{{ reportData.visual_acuities.distance_left_ph }}</td>
                    <td>{{ reportData.visual_acuities.near_left_unaided }}</td>
                    <td>{{ reportData.visual_acuities.near_left_aided }}</td>
                  </tr>
                </table>
              </div>

              <div class="row mt-0">
                <div class="col-md-12">
                  <p>
                    <span class="title-key2">VISUAL ACUITY Notes:</span>
                    <span
                      class="value-key"
                      v-html="reportData.visual_acuities.note"
                    ></span>
                  </p>
                </div>
              </div>

              <table id="table2" class="mt-0">
                <tr>
                  <th colspan="11">
                    <p class="title-key">
                      Present Glass Power | Contact Lenses Parameter
                    </p>
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th colspan="5">RE</th>
                  <th colspan="5">LE</th>
                </tr>
                <tr>
                  <td><p class="title-key2">Contact Lenses Parameter</p></td>
                  <td colspan="5">
                    {{ reportData.contact_lenses_parameter.right_read }}
                  </td>
                  <td colspan="5">
                    {{ reportData.contact_lenses_parameter.left_read }}
                  </td>
                </tr>
                <tr>
                  <td rowspan="2">
                    <p class="title-key2">Present Glass Power</p>
                  </td>
                  <td>SPH</td>
                  <td>CYL</td>
                  <td>AXIS</td>
                  <td>ADD</td>
                  <td>VA</td>
                  <td>SPH</td>
                  <td>CYL</td>
                  <td>AXIS</td>
                  <td>ADD</td>
                  <td>VA</td>
                </tr>
                <tr>
                  <td>{{ reportData.present_glass_power.right_sph }}</td>
                  <td>{{ reportData.present_glass_power.right_cyl }}</td>
                  <td>{{ reportData.present_glass_power.right_axis }}</td>
                  <td>{{ reportData.present_glass_power.right_add }}</td>
                  <td>{{ reportData.present_glass_power.right_va }}</td>
                  <td>{{ reportData.present_glass_power.left_sph }}</td>
                  <td>{{ reportData.present_glass_power.left_cyl }}</td>
                  <td>{{ reportData.present_glass_power.left_axis }}</td>
                  <td>{{ reportData.present_glass_power.left_add }}</td>
                  <td>{{ reportData.present_glass_power.left_va }}</td>
                </tr>
              </table>

              <div class="row mt-0">
                <div class="col-md-12">
                  <p>
                    <span class="title-key2">Present Glass Power Notes:</span>
                    <span
                      class="value-key"
                      v-html="reportData.present_glass_power.note"
                    ></span>
                  </p>
                </div>
              </div>

              <table id="table3" class="mt-0">
                <tr>
                  <th></th>
                  <th colspan="5">RE</th>
                  <th colspan="5">LE</th>
                </tr>
                <tr>
                  <td><p class="title-key2">Retinoscopy</p></td>
                  <td colspan="5">{{ reportData.retinoscopy.right_read }}</td>
                  <td colspan="5">{{ reportData.retinoscopy.left_read }}</td>
                </tr>
                <tr>
                  <td><p class="title-key2">Autorefractometry value</p></td>
                  <td colspan="5">
                    {{ reportData.autorefractometry_value.right_read }}
                  </td>
                  <td colspan="5">
                    {{ reportData.autorefractometry_value.left_read }}
                  </td>
                </tr>
                <tr>
                  <td rowspan="2">
                    <p class="title-key2">Final Prescription</p>
                  </td>
                  <td>SPH</td>
                  <td>CYL</td>
                  <td>AXIS</td>
                  <td>ADD</td>
                  <td>VA</td>
                  <td>SPH</td>
                  <td>CYL</td>
                  <td>AXIS</td>
                  <td>ADD</td>
                  <td>VA</td>
                </tr>
                <tr>
                  <td>{{ reportData.final_prescription.right_sph }}</td>
                  <td>{{ reportData.final_prescription.right_cyl }}</td>
                  <td>{{ reportData.final_prescription.right_axis }}</td>
                  <td>{{ reportData.final_prescription.right_add }}</td>
                  <td>{{ reportData.final_prescription.right_va }}</td>
                  <td>{{ reportData.final_prescription.left_sph }}</td>
                  <td>{{ reportData.final_prescription.left_cyl }}</td>
                  <td>{{ reportData.final_prescription.left_axis }}</td>
                  <td>{{ reportData.final_prescription.left_add }}</td>
                  <td>{{ reportData.final_prescription.left_va }}</td>
                </tr>
              </table>

              <div class="row mt-0">
                <div class="col-md-12">
                  <p>
                    <span class="title-key2">Final Prescription Notes:</span>
                    <span
                      class="value-key"
                      v-html="reportData.final_prescription.note"
                    ></span>
                  </p>
                </div>
              </div>

              <div class="row mt-0">
                <div class="col-md-12">
                  <p>
                    <span class="title-key2">OTHER DIAGNOSTIC TESTS:</span>
                    <span
                      class="value-key"
                      v-html="reportData.other_diagnostic_tests"
                    ></span>
                  </p>
                </div>
              </div>

              <div class="row mt-0">
                <div class="col-md-12">
                  <p>
                    <span class="title-key2">DIAGNOSIS Right:</span>
                    <span class="value-key">
                      <template
                        v-if="
                          reportData.diagnosis &&
                          reportData.diagnosis.length > 0
                        "
                      >
                        <span
                          v-for="(diag, index) in reportData.diagnosis"
                          :key="index"
                        >
                          {{ diag }}
                          <span v-if="index != reportData.diagnosis.length - 1"
                            >,</span
                          >
                        </span>
                      </template>
                    </span>
                  </p>
                </div>
              </div>
              <div class="row mt-0">
                <div class="col-md-12">
                  <p>
                    <span class="title-key2">DIAGNOSIS Left:</span>
                    <span class="value-key">
                      <template
                        v-if="
                          reportData.left_diagnosis &&
                          reportData.left_diagnosis.length > 0
                        "
                      >
                        <span
                          v-for="(diag, index) in reportData.left_diagnosis"
                          :key="index"
                        >
                          {{ diag }}
                          <span v-if="index != reportData.left_diagnosis.length - 1"
                            >,</span
                          >
                        </span>
                      </template>
                    </span>
                  </p>
                </div>
              </div>

               <div class="row mt-0">
                <div class="col-md-12">
                  <p>
                    <span class="title-key2">DIAGNOSIS NOTE:</span>
                    <span
                      class="value-key"
                      v-html="reportData.diagnosis_note"
                    ></span>
                  </p>
                </div>
              </div>

              <div class="row mt-0">
                <div class="col-md-12">
                  <p>
                    <span class="title-key2">PLAN OF MANAGEMENT:</span>
                    <span
                      class="value-key"
                      v-html="reportData.plan_of_management"
                    ></span>
                  </p>
                </div>
              </div>

              <div class="row mt-0">
                <div class="col-md-6">
                  <p>
                    <span class="title-key2">REVIEW / NOTE:</span>
                    <span
                      class="value-key"
                      v-html="reportData.review_note"
                    ></span>
                  </p>
                </div>
                <div
                  class="col-md-6"
                  style="text-align: right; margin: 0 !important ;"
                >
                  <div>
                    <span class="title-key2">SIGN & STAMP:</span>
                  </div>
                  
                  <div class="mt-1">
                     <img width="140" height="40"
                    style="margin:0 auto;"
                     :src="reportData.signature_text?`data:image/png;base64,${reportData.signature_text}`:''" alt="">
                  </div>

                  <div class="mt-2">
                    <img
                      class="signature"
                      width="140"
                      height="40"
                      :src="reportData.signature?reportData.signature: ''"
                      alt=""
                      style="display:inline-block;margin:0 auto;"
                    />
                  </div>
                </div>

                <div class="col-md-6" style="text-align: right">
                  <p>
                    <span class="title-key2"></span>
                    <span class="value-key"></span>
                  </p>
                </div>
              </div>
            </div>

            <div name="page-footer">
              <table
                style="
                  border-collapse: collapse;
                  border-spacing: 0;
                  width: 100%;
                "
              >
                <tbody>
                  <tr>
                    <td
                      style="
                        width: 100%;
                        background: #333333;
                        color: #fff;
                        padding: 8px;
                        font-size: 12px;
                        vertical-align: middle;
                        font-weight: 500;
                        text-align: center;
                      "
                    >
                      C. No:1023991 Saltant of Oman –Buraimi Whatsapp: +968
                      71120244 /ntoosh@hotmail.com
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="footer"></div>
            </div>
          </div>
        </div>
      </div>
    </body>
  </div>
</template>

  <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/popper.js@1.12.9/dist/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>

<script>
export default {
  data() {
    return {
      reportData: null,
      config: {
        crudHeader: "Optometrists",
        crudApi: "optometristReport",
      },
      id: this.$route.params.id || null,
    };
  },
  methods: {
    initData() {
      this.$store
        .dispatch(`${this.config.crudApi}/pdfReport`, this.id)
        .then((res) => {
          this.reportData = res.data;
        });
    },
    print() {
      window.print();
    },
  },
  created() {
    this.initData();
  },
};
</script>


<style lang="scss">
.reportDetails {
  direction: ltr;
}

.signature {
  display: block;
  margin: 5px 500px;
}

.reportPage {
  @page {
    header: page-header;
    content: page-content;
    footer: page-footer;
  }

  @font-face {
    font-family: HacenTunisia !important;
    src: "/assets/fonts/HacenTunisia.ttf";
    font-weight: 500;
  }

  .header {
    position: static;
    top: 0;
    padding-bottom: 60px;
    background: url("/assets/top.png");
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .footer {
    padding-top: 40px;
    position: static;
    bottom: 0px;
    background: url("/assets/footer.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .title-key {
    font-weight: bold;
  }
  .title-key2 {
    font-weight: bold;
    background: #ccc;
  }
  .value-key {
    margin: 0 10px;
    /* font-weight: bold; */
  }
  .active-gender {
    border: 2px solid #1a5064;
    border-radius: 50%;
    padding: 4px 6px;
  }

  #table1,
  #table2,
  #table3 {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin: 5px 0;
  }

  #table1 {
    width: 98%;
    position: relative;
    left: 15px;
  }

  #table1 td,
  #table2 td,
  #table3 td,
  #table1 th,
  #table2 th,
  #table3 th {
    border: 1px solid #000 !important;
    text-align: center;
    font-size: 13px;
    padding: 8px;
  }

  #table1 th,
  #table2 th,
  #table3 th {
    background-color: #fff !important;
    border: 1px solid #000 !important;
  }
}

p {
  margin: 4px 10px !important;
}
@media print {
  #table1 {
    width: 97.3% !important;
  }

  .signature {
    display: block;
    margin: 5px 300px 0 360px;
  }
}

.value-key *,
.value-key2 * {
  display: inline-block !important;
}
</style>